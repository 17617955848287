@use '../../base-styles/base-styles.scss' as base;
@use '../../base-styles/theme-colors.styles.scss';

.hamburger-menu {
    @include base.widthAndHeight(3rem, 2.5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    border: hidden;
    background-color: transparent;
    position: relative;
    margin: auto 0.5em auto 0.2em;

    @media (min-width: 769px){
        display: none;
    }

    &:focus {
        outline: none;
        filter: drop-shadow(0 0 3px var(--important-object-colour));
    }

    .burger-line {
        @include base.widthAndHeight(3rem, 2px);
        background-color: var(--important-object-colour);
        border-radius: 5px;
    }

    .line1 {
        width: 2rem;
    }

    .line3 {
        width: 1rem;
    }
}

.visually-hidden {
    @include base.visuallyHidden;
}