@use '../../base-styles/base-styles.scss' as base;
@use '../../base-styles/theme-colors.styles.scss';

.header {
    @include base.widthAndHeight(100%, 6rem);
    max-width: 150rem;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--page-background-colour);
    box-shadow: 0 0 5px rgba(black, 0.5);

    @media (min-width: 1500px){
        position: sticky;
        left: calc((100% - 130rem) / 2);
        box-shadow: none;
    }
}

.title-wrapper {
    width: 45%;
    max-width: 165px;
    padding-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    border-right: 0.5px solid var(--important-object-colour);

    @media (max-width: 330px){
        width: 60%;
    }

    .name,
    .job-title {
        width: 100%;
    }

    .name {
        font-size: 2rem;
        color: var(--important-object-colour);
    }

    .job-title {
        font-size: 1.3rem;
        text-transform: uppercase;
    }
}

.header-actions {
    @include base.widthAndHeight(30%, 100%);
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;

    @media (min-width: 769px){
        width: 55%;
    }

    @media (max-width: 330px){
        width: 35%;
    }

    .header-nav {
        @include base.widthAndHeight(90%, 100%);
        display: none;
        align-items: center;

        @media (min-width: 769px){
            display: flex;
        }

        ul {
            width: 100%;
            list-style: none;
            display: flex;
            justify-content: space-around;

            li {
                height: 100%;

                a {
                    position: relative;
                    color: var(--important-object-colour);
                    text-decoration: none;

                    &:after {
                        border-top: 2px solid var(--important-object-colour);
                        border-radius: 2em;
                        content: "";
                        position: absolute;
                        right: 100%;
                        bottom: -0.2em;
                        left: 0;
                        transform: rotate(-4deg);
                        transition: right 450ms cubic-bezier(0, 0.5, 0, 1);
                    }

                    &:focus,
                    &:hover {
                        outline: none;

                        &::after {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}

.theme-btn-wrapper {
    height: 100%;
    display: grid;
    place-items: center;
    cursor: pointer;

    svg {
        @include base.widthAndHeight(40px, 70%);
        fill: var(--important-object-colour);

        &:focus,
        &:hover {
            outline: none;
            filter: drop-shadow(0 0 3px var(--important-object-colour)) !important;
            border: 0.5px solid var(--important-object-colour);
        }
    }
}

@media (prefers-color-scheme: dark) {
    .header-actions {
        justify-content: flex-end;
    }
    .header-actions .header-nav {
        width: 100%;
    }
    .theme-btn-wrapper {
        display: none;
    }
}