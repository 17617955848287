/* mixins */
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin visuallyHidden {
    @include widthAndHeight(1px, 1px);
    position: absolute;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* Support for IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
}

@mixin widthAndHeight($width, $height){
    width: $width;
    height: $height;
}

/* Function(s) */
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

/* Font Sizes */
$min_screen-width: 25rem;
$max_screen-width: 80rem;
$min_font: 1.7rem;
$max_font: 1.9rem;
$mod_1: 1.20; // mobile
$mod_2: 1.28; // desktop

/* Styles */
:root {
    font-size: 10px;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_screen-width, $max_screen-width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_screen-width, $max_screen-width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_screen-width, $max_screen-width, $mod_1 *$min_font, $mod_2 *$min_font);
}

h4 {
    font-size: $mod_1 *1rem;
    @include fluid-type($min_screen-width, $max_screen-width, $min_font, $min_font);
}

body {
    @include fluid-type($min_screen-width, $max_screen-width, $min_font, $max_font);
}