@use '../../base-styles/theme-colors.styles.scss';
@use '../../base-styles/base-styles.scss' as base;

.drawer-container {
    @include base.widthAndHeight(70%, 100vh);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
    flex-direction: column;
    background-color: var(--page-background-colour);
    box-shadow: 0 0 2px 4px rgba(black, 0.3);
    transition: opacity 300ms ease-in-out, transform 300ms ease-out;
    transform-origin: 100% 0;
}

.drawer-header {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: flex-end;

    .close-btn {
        border: none;
        padding-right: 1rem;
        background-color: transparent;
        color: var(--important-object-colour);
        font-size: 3em;

        &:focus {
            outline: none;
            filter: drop-shadow(0 0 3px var(--important-object-colour));
        }
    }
}

.drawer-nav {
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
        @include base.widthAndHeight(100%, 50px);

        a {
            @include base.widthAndHeight(100%, 100%);
            padding-left: 1.5em;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--page-text-colour);

            &:hover,
            &:focus,
            &:active {
                text-shadow: 0 0 3px var(--page-text-colour);
            }
        }
    }
}

.drawer-container-exited {
    display: none;
    opacity: 0;
    transform: scale(0);
}

.drawer-container-entering,
.drawer-container-exiting {
    opacity: 0.1;
    transform: scale(0.25);
}

.drawer-container-entered {
    display: flex;
    opacity: 1;
    transform: scale(1);
}