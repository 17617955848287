@use './base-styles/theme-colors.styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap');

html {
	scroll-behavior: smooth;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	transition: color 100ms ease-in,
				background-color 300ms ease-in,
				background 300ms ease-in,
				box-shadow 300ms ease,
				fill 300ms ease;
}

.transitions-off {
	* {
		transition: unset !important;
	}
}

.App {
	min-height: 100vh;
	margin: 0 auto;
	max-width: 150rem;
	font-family: 'Noto Sans JP', sans-serif;
}

[id] {
	scroll-margin-top: 6rem;
}